import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { FaGitlab, IoLogoVercel } from 'react-icons/all'
import BlockContent from '@sanity/block-content-to-react'
import { PageWrapper, ButtonComponentStyles } from '../styles/GenericStyles'
import SEO from '../components/SEO'

const Wrapper = styled(PageWrapper)`
  .container {
    border-left: 1px solid green;
    margin: 5em 0 0 5em;
    padding-left: 2em;
  }
  .darker-grey {
    color: var(--darker-grey);
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    .container {
      margin: 0;
      padding-left: 0.5em;
    }
  }
`

const ProjectHeaderStyles = styled.div`
  p,
  a {
    margin: 0.7em 0;
  }
  a {
    margin-right: 2em;
  }
`
const headerImageStyle = {
  margin: '1em 0',
}

function ProjectHeader({
  props: { pageTitle, projectUrl, repoUrl, technologies, image },
}) {
  return (
    <>
      <SEO title="Project: a todo app" />
      <ProjectHeaderStyles>
        <h1>{pageTitle}</h1>
        <p className="darker-grey">{technologies}</p>
        <ButtonComponentStyles as="a" href={repoUrl} target="_blank">
          <FaGitlab /> see the code
        </ButtonComponentStyles>
        <ButtonComponentStyles
          deg="rotate(-5deg)"
          as="a"
          href={projectUrl}
          target="_blank"
        >
          <IoLogoVercel /> see the working project
        </ButtonComponentStyles>

        <Img fluid={image.asset.fluid} style={headerImageStyle} />
      </ProjectHeaderStyles>
    </>
  )
}

ProjectHeader.propTypes = {
  props: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    technologies: PropTypes.string.isRequired,
    repoUrl: PropTypes.string,
    projectUrl: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
}

const ProjectPresentationStyles = styled.div`
  margin: 1.5em 0;
  p {
    margin: 1em 0;
  }
  span {
    color: var(--lime);
  }

  li {
    margin-left: 1em;
    padding: 0.5em 0;
  }

  h2 {
    margin-top: 1em;
  }

  .image-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1em;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .image-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`

function ProjectPresentation({
  props: { subTitle, introText, rawProblem, applicationImages },
}) {
  return (
    <ProjectPresentationStyles>
      <p>
        <span>{subTitle}: </span>
        {introText}
      </p>
      <h2>Challenges</h2>
      <BlockContent blocks={rawProblem} />
      <div className="image-container">
        <Img fluid={applicationImages[0].asset.fluid} />
        <Img fluid={applicationImages[1].asset.fluid} />
        <Img fluid={applicationImages[2].asset.fluid} />
        <Img fluid={applicationImages[3].asset.fluid} />
        <Img fluid={applicationImages[4].asset.fluid} />
      </div>
    </ProjectPresentationStyles>
  )
}

ProjectPresentation.propTypes = {
  props: PropTypes.shape({
    subTitle: PropTypes.string.isRequired,
    introText: PropTypes.string.isRequired,
    applicationImages: PropTypes.array.isRequired,
    rawProblem: PropTypes.array.isRequired,
  }),
}

const ProjectSolutionStyles = styled.div`
  margin: 1.5em 0;
  li {
    margin-left: 1em;
    padding: 0.5em 0;
  }

  h2 {
    margin-top: 1em;
  }
`

function ProjectSolution({ props: { rawSolution } }) {
  return (
    <ProjectSolutionStyles>
      <h2>What I learned</h2>
      <BlockContent blocks={rawSolution} />
    </ProjectSolutionStyles>
  )
}

ProjectSolution.propTypes = {
  props: PropTypes.shape({
    rawSolution: PropTypes.array,
  }),
}

function photosnap(props) {
  const data = props.data.allSanityProject.nodes[0]
  return (
    <Wrapper>
      <div className="container">
        <ProjectHeader
          props={{
            pageTitle: data.pageTitle,
            projectUrl: data.projectUrl,
            repoUrl: data.repoUrl,
            image: data.image,
            technologies: data.technologies,
          }}
        />
        <ProjectPresentation
          props={{
            subTitle: data.subTitle,
            introText: data.introText,
            rawProblem: data._rawProblem,
            applicationImages: data.applicationImages,
          }}
        />
        <ProjectSolution
          props={{
            rawChallenges: data._rawChallenges,
            rawSolution: data._rawSolution,
          }}
        />
        <div className="grid">
          <Img fluid={data.applicationImages[5].asset.fluid} />
        </div>
      </div>
    </Wrapper>
  )
}

export default photosnap

export const query = graphql`
  query PhotosnapQuery($slug: String) {
    allSanityProject(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        name
        projectUrl
        repoUrl
        pageTitle
        subTitle
        technologies
        introText
        _rawProblem
        _rawRole
        _rawChallenges
        _rawSolution
        image {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        applicationImages {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        clientImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
